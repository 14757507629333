import * as React from "react";
import {StyleProp, StyleSheet, View, ViewStyle} from "react-native";
import {SessionPreview} from "../../../@types/activities/session";
import {DEFAULT_SPACING, IS_CLIENT, IS_INTERPRETER, SMALL_SPACING} from "../../../utils/constants";
import {formatDuration, formatShortDateTimeRange, formatShortTimeRange} from "../../../utils/date-time/format";
import {useTranslation} from "../../../utils/hooks/use-translation";
import {getCommunicationTypeIcon, getTypeColor} from "../../../utils/sessions/communications";
import {getStatusColor} from "../../../utils/sessions/status";
import {PRIMARY_2, SUBTLE_4, WHITE} from "../../../utils/styles/colors";
import {Label} from "../../labels/label";
import {TranslationLabel} from "../../labels/translation";
import {Spacer} from "../../spacer";
import {Text} from "../../texts/text";

interface SessionPreviewElementProps {
	allowFontScaling?: boolean;
	session: SessionPreview;
	showDate?: boolean;
	style?: StyleProp<ViewStyle>;
}

export const SessionPreviewElement = ({
	session: {start, end, status, ...session},
	showDate,
	style,
	allowFontScaling = true,
}: SessionPreviewElementProps): JSX.Element => {
	const {ct, t} = useTranslation();
	return (
		<View style={style}>
			<View style={styles.dateTextLine1}>
				<View style={styles.subTitle}>
					<Text type="button_1" allowFontScaling={allowFontScaling}>
						{
							showDate
								? `${formatShortDateTimeRange(start, end)}${session?.immediate
									? ` - ${ct("common:immediate")}`
									: ""}`
								: session?.immediate
									? `${ct("common:immediate")} - ${formatDuration(start, end)}`
									: formatShortTimeRange(start, end)
						}
					</Text>
					<Spacer size={DEFAULT_SPACING} mode="vertical"/>
					<Text type="button_2" color={SUBTLE_4} allowFontScaling={allowFontScaling}>
						{`#${session?.activityId}`}
					</Text>
					<Spacer size={DEFAULT_SPACING} mode="vertical"/>
					<Text type="button_2" color={getStatusColor(status)} allowFontScaling={allowFontScaling}>
						{t(`activities:sessions.status.${status}`)}
					</Text>
				</View>
			</View>
			<View style={styles.labels}>
				<Label
					icon={getCommunicationTypeIcon(session.communication.type)}
					backgroundColor={getTypeColor(session.communication.type)}
					size="small"
					allowFontScaling={allowFontScaling}
				/>
				{IS_INTERPRETER && (
					<>
						<Spacer mode="vertical" size={SMALL_SPACING / 2}/>
						<Label
							icon={session.type === "interpreterMandate" && session.onlyRecipient ? "personOutline" : "peopleOutline"}
							backgroundColor={WHITE}
							color={PRIMARY_2}
							borderColor={SUBTLE_4}
							size="small"
							allowFontScaling={allowFontScaling}
						/>
					</>
				)}
				{(session?.price ?? session?.paid) && (
					<>
						<Spacer mode="vertical" size={SMALL_SPACING / 2}/>
						<Label
							icon="money"
							backgroundColor={WHITE}
							color={PRIMARY_2}
							borderColor={SUBTLE_4}
							size="small"
							allowFontScaling={allowFontScaling}
						/>
					</>
				)}
				<Spacer mode="vertical" size={SMALL_SPACING / 2}/>
				{session.type === "interpreterMandate" &&
					<TranslationLabel
						from={session.language}
						to={session.toLanguage}
						size="small"
						allowFontScaling={allowFontScaling}
					/>
				}
				<Spacer mode="vertical" size={SMALL_SPACING / 2}/>
			</View>
			<Text type="label" numberOfLines={1} color={PRIMARY_2} allowFontScaling={allowFontScaling}>
				{/* eslint-disable-next-line react/destructuring-assignment,unicorn/consistent-destructuring */}
				{session.communication.type === "inPerson"
					/* eslint-disable-next-line react/destructuring-assignment,unicorn/consistent-destructuring */
					? session.communication.place.address
					: status === "sent" || status === "confirmed"
						? start.getTime() < Date.now()
							? (IS_CLIENT || status === "confirmed") &&
							t("activities:sessions.call.started", {duration: formatDuration(start, new Date())})
							: t("activities:sessions.call.start", {duration: formatDuration(start, new Date())})
						: t("activities:sessions.call.done", {duration: formatDuration(start, new Date())})
				}
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	dateTextLine1: {
		alignItems: "center",
		flexDirection: "row",
		marginBottom: 4,
	},
	labels: {
		flexDirection: "row",
		marginBottom: 4,
	},
	subTitle: {
		flexDirection: "row",
		flexShrink: 1, // Do not align statusLabel right but avoids subTitle taking too much space
		// flex: 1, // align statusLabel right and avoids subTitle taking too much space
	},
});
