export const languages = {
	akuapem: "Akuapem",
	albanian: "Albanian",
	amharic: "Amharic",
	arab: "Arabic",
	"arab-maghreb": "Arabic (Maghrebi)",
	"arabic-classical": "Arabic (Classical)",
	"arabic-levantine": "Arabic (Levantine)",
	aramaic: "Aramaic",
	armenian: "Armenian",
	asante: "Asante",
	azerbaijani: "Azerbaijani",
	azeri: "Azeri",
	bambara: "Bambara",
	basque: "Basque",
	bengali: "Bengali",
	berber: "Berber",
	bilen: "Bilen",
	bosnian: "Bosnian",
	bulgarian: "Bulgarian",
	catalan: "Catalan",
	chechen: "Chechen",
	"chinese-cantonese": "Chinese Cantonese",
	"chinese-mandarin": "Chinese Mandarin",
	creole: "Creole",
	croatian: "Croatian",
	czech: "Czech",
	dari: "Dari",
	diola: "Diola",
	dutch: "Dutch",
	dyula: "Dyula",
	english: "English",
	esan: "Esan",
	ewe: "Ewe",
	fante: "Fante",
	farsi: "Farsi (Persian)",
	french: "French",
	fula: "Fula",
	fulfude: "Fulfude",
	ga: "Ga",
	gagauz: "Gagauz",
	georgian: "Georgian",
	german: "German",
	greek: "Greek",
	guarani: "Guarani",
	gujarati: "Gujarati",
	hausa: "Hausa",
	hazaragi: "Hazaragi",
	hebrew: "Hebrew",
	hindi: "Hindi",
	hungarian: "Hungarian",
	igbo: "Igbo",
	italian: "Italian",
	japanese: "Japanese",
	kazakh: "Kazakh",
	khmer: "Khmer (Cambodia)",
	kikongo: "Kikongo",
	kinyarwanda: "Kinyarwanda",
	kirgisisch: "Kyrgyz",
	kirundi: "Kirundi",
	kissi: "Kissi",
	kiswahili: "Kiswahili",
	korean: "Korean",
	"kurdish-badini": "Kurdish Badini",
	"kurdish-kurmanji": "Kurdish Kurmanji",
	"kurdish-kurmanji-(syria)": "Kurdish Kurmanji (Syria)",
	"kurdish-kurmanji-(turkey)": "Kurdish Kurmanji (Turkey)",
	"kurdish-sorani": "Kurdish Sorani",
	"kurdish-zazaki": "Kurdish Zazaki",
	kutchi: "Kutchi",
	lao: "Lao",
	latvian: "Latvian",
	lingala: "Lingala",
	macedonian: "Macedonian",
	malagasy: "Malagasy",
	malayalam: "Malayalam",
	malaysian: "Malaysian",
	malinke: "Malinke",
	mandingo: "Mandingo",
	mandinka: "Mandinka",
	masalit: "Masalit",
	mbaye: "Mbaye",
	mina: "Mina",
	mongolian: "Mongolian",
	nepali: "Nepali",
	ngambaye: "Ngambaye",
	nyabode: "Nyabode",
	oromo: "Oromo",
	pashto: "Pashto",
	persan: "Persan",
	"pidgin-english": "Pidgin English",
	polish: "Polish",
	portugese: "Portugese",
	"portugese-brasil": "Portugese (Brasil)",
	"portugese-portugal": "Portugese (Portugal)",
	pulaar: "Pulaar",
	punjabi: "Punjabi",
	romani: "Romani",
	romanian: "Romanian",
	russian: "Russian",
	saho: "Saho",
	serbian: "Serbian",
	sidama: "Sidama",
	sinhalese: "Sinhalese",
	slovakian: "Slovakian",
	slovenian: "Slovenian",
	somali: "Somali",
	soninke: "Soninke",
	soussou: "Soussou",
	spanish: "Spanish",
	swahili: "Swahili",
	"swiss-german": "Swiss-German",
	tagalog: "Tagalog (Philippines)",
	tajik: "Tajik",
	tamil: "Tamil",
	thai: "Thai",
	tibetan: "Tibetan",
	tigre: "Tigre",
	tigrinya: "Tigrinya",
	tshiluba: "Tshiluba",
	turkish: "Turkish",
	turkmen: "Turkmen",
	twi: "Twi",
	ukrainian: "Ukrainian",
	"ukrainian-russian": "Ukrainian (Russian-speaking)",
	urdu: "Urdu",
	uyghur: "Uyghur",
	uzbek: "Uzbek",
	vietnamien: "Vietnamien",
	wolof: "Wolof",
	yoruba: "Yoruba",
};
