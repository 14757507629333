import {languages as enUSLanguages} from "../en-US/languages";

export const languages: typeof enUSLanguages = {
	akuapem: "Akuapem",
	albanian: "Albanese",
	amharic: "Amarico",
	arab: "Arabo",
	"arab-maghreb": "Arabo (Maghrebino)",
	"arabic-classical": "Arabo (Classico)",
	"arabic-levantine": "Arabo (Levantino)",
	aramaic: "Aramaico",
	armenian: "Armeno",
	asante: "Asante",
	azerbaijani: "Azerbaigiana",
	azeri: "Azero",
	bambara: "Bambara",
	basque: "Basco",
	bengali: "Bengalese",
	berber: "Berbero",
	bilen: "Bilen",
	bosnian: "Bosniaco",
	bulgarian: "Bulgaro",
	catalan: "Catalana",
	chechen: "Ceceno",
	"chinese-cantonese": "Cinese cantonese",
	"chinese-mandarin": "Cinese mandarino",
	creole: "Creolo",
	croatian: "Croato",
	czech: "Ceco",
	dari: "Dari",
	diola: "Diola",
	dutch: "Neerlandese",
	dyula: "Dioula",
	english: "Inglese",
	esan: "Esan",
	ewe: "Ewe",
	fante: "Fante",
	farsi: "Farsi (Persiano)",
	french: "Francese",
	fula: "Fula",
	fulfude: "Foulfouldé",
	ga: "Ga",
	gagauz: "Gagauz",
	georgian: "Georgiano",
	german: "Tedesco",
	greek: "Greco",
	guarani: "Guaraní",
	gujarati: "Gujarati",
	hausa: "Hausa",
	hazaragi: "Hazaragi",
	hebrew: "Ebraico",
	hindi: "Hindi",
	hungarian: "Ungherese",
	igbo: "Igbo",
	italian: "Italiano",
	japanese: "Giapponese",
	kazakh: "Kazako",
	khmer: "Khmer (Cambogiano)",
	kikongo: "Kikongo",
	kinyarwanda: "Kinyarwanda",
	kirgisisch: "Kirghizo",
	kirundi: "Kirundi",
	kissi: "Kissi",
	kiswahili: "Kiswahili",
	korean: "Coreano",
	"kurdish-badini": "Curdo Badini",
	"kurdish-kurmanji": "Curdo kurmanji",
	"kurdish-kurmanji-(syria)": "Curdo Kurmanji (Siria)",
	"kurdish-kurmanji-(turkey)": "Curdo Kurmanji (Turchia)",
	"kurdish-sorani": "Curdo Sorani",
	"kurdish-zazaki": "Curdo Zazaki",
	kutchi: "Katchi",
	lao: "Laotiano",
	latvian: "Lettone",
	lingala: "Lingala",
	macedonian: "Macedone",
	malagasy: "Malgascio",
	malayalam: "Malayalam",
	malaysian: "Malese",
	malinke: "Malinka",
	mandingo: "Mandinga",
	mandinka: "Mandinka",
	masalit: "Masalit",
	mbaye: "Mbaye",
	mina: "Mina",
	mongolian: "Mongolo",
	nepali: "Nepali",
	ngambaye: "Ngambaye",
	nyabode: "Nyabode",
	oromo: "Oromo",
	pashto: "Pashto",
	persan: "Persan",
	"pidgin-english": "Broken Inglese",
	polish: "Polacco",
	portugese: "Portoghese",
	"portugese-brasil": "Portoghese (Brasile)",
	"portugese-portugal": "Portoghese (Portogallo)",
	pulaar: "Peul",
	punjabi: "Panjabi",
	romani: "Romani",
	romanian: "Rumeno",
	russian: "Russo",
	saho: "Saho",
	serbian: "Serbo",
	sidama: "Sidama",
	sinhalese: "Singalese",
	slovakian: "Slovacco",
	slovenian: "Sloveno",
	somali: "Somalo",
	soninke: "Soninke",
	soussou: "Soussou",
	spanish: "Spagnolo",
	swahili: "Swahilli",
	"swiss-german": "Svizzero tedesco",
	tagalog: "Tagalog (Filippino)",
	tajik: "Tagico",
	tamil: "Tamil",
	thai: "Thai",
	tibetan: "Tibetano",
	tigre: "Tigre",
	tigrinya: "Tigrinya",
	tshiluba: "Tshiluba",
	turkish: "Turco",
	turkmen: "Turkmeno",
	twi: "Twi",
	ukrainian: "Ucraino",
	"ukrainian-russian": "Ucraino (di lingua russa)",
	urdu: "Urdu",
	uyghur: "Uiguro",
	uzbek: "Uzbeco",
	vietnamien: "Vietnamita",
	wolof: "Wolof",
	yoruba: "Yoruba",
};
